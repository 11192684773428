@import "../../../../layout/variables";

.writeReview {
  margin-bottom: $margin-spacing-section;
}

.formSection {
  margin-bottom: $margin-spacing-item;
}


.formTitle {
  font-size: $font-size-large;
  font-weight: bold;
  margin-bottom: $margin-spacing-item;
}

.columns {
  display: flex;
  margin-bottom: $margin-spacing-item;
}

.column {
  flex-grow: 1;
  &:first-child {
    margin-right: $padding-internal;
  }
}

