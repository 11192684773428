@import "../../layout/variables";

.articleCard {
  border: 1px solid $color-dark-grey;
  text-decoration: none;
  width: 350px;
  flex-shrink: 0;
  height: 435px;
  margin-right: 2rem;
  filter: $filter-shadow;
  display: block;
  background-color: $color-dark-grey;
  color: $color-white;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    transform: translateX(0) translateY(0) scaleX($hover-scale)
      scaleY($hover-scale) rotate(0deg) skewX(0deg) skewY(0deg);
    transition: all 0.4s ease-in-out 0s;
  }
}

.caption {
  padding: 1rem;
}

.title {
  font-size: $font-size-large;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.preview {
  overflow: hidden;
  text-overflow: ellipsis;
}

.backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 240px;
}
