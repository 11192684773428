$color-dark-grey: rgb(33, 33, 33);
$color-medium-grey: rgb(89, 89, 89);
$color-light-grey: rgb(120, 120, 120);
$color-very-light-grey: #cccccc;
$color-turquoise: #13928c;
$color-turquoise-light: #28e3da;
$color-yellow: #ddfa62;
$color-white: #ffffff;
$color-red: #d03161;

$gradient: radial-gradient(
  circle at 50% 50%,
  $color-turquoise-light 0%,
  $color-turquoise 100%
);

$width-max: 1600px;
$breakpoint-desktop: 768px;

$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 24px;
$font-size-breadcrumbs: 14px;
$font-size-page-title-desktop: 56px;
$font-size-page-title: 32px;
$font-size-x-large: 42px;
$font-size-hero: 64px;
$font-size-hero-desktop: 84px;
$font-line-height: 1.2;
$font-family:
  HelveticaNeue,
  Helvetica Neue,
  helvetica,
  Sans-Serif;

$margin-space-y: 2rem;
$margin-spacing-section: 2rem;
$margin-spacing-item: 1rem;

$padding-internal: 1rem;
$padding-internal-separator: 0.5rem;

$border-separator: 1px solid $color-light-grey;
$border-radius-medium: 6px;
$border-radius-small: 4px;

$hover-scale: 1.1;

$filter-shadow: drop-shadow(0 0 6px #444);

$transition-button-hover:
  all 0.4s ease,
  visibility 0s;
