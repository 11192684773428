@import "../../layout/variables";

.button {
  background-color: $color-yellow;
  padding: 1rem 2rem;
  color: $color-dark-grey;
  border: 1px solid $color-yellow;
  cursor: pointer;
  font-size: $font-size-medium;
  border-radius: $border-radius-small;
  text-decoration: none;
  text-align: center;

  &.small {
    padding: 0.5rem 1.5rem;
  }

  &.dark {
    background-color: $color-dark-grey;
    color: $color-yellow;

    &:hover {
      background-color: $color-yellow;
      color: $color-dark-grey;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &:hover {
    background-color: $color-dark-grey;
    color: $color-yellow;
    transition: $transition-button-hover;
  }
}
