@import "./variables";

body {
  margin: 0;
  background-color: $color-dark-grey;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-white;
  font-size: $font-size-medium;
  line-height: $font-line-height;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  padding: 0 1rem;
  margin: 0 auto;

  @media (min-width: $breakpoint-desktop) {
    min-width: $breakpoint-desktop;
    width: 80%;
    max-width: $width-max;
  }
}

.pageTitle {
  text-transform: capitalize;
  color: $color-white;
  text-align: center;
  font-size: $font-size-page-title;
  font-weight: bold;
  margin: $margin-space-y;

  @media (min-width: $breakpoint-desktop) {
    font-size: $font-size-page-title-desktop;
  }

  &.darkGrey {
    color: $color-dark-grey;
  }

  &.white {
    color: $color-white;
  }
}
