@import "../../../layout/variables";

.section {
  padding: 1rem 0;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
}
