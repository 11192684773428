@import "../../../layout/variables";
.OfferCaption {
}

.pill {
  display: inline-block;
  margin: 0.5rem 0 0.5rem 0;
  background-color: $color-turquoise;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.caption {
  margin-top: 0.5rem;
}

.sticky {
  background-color: $color-red;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
}
