@import "variables";
.logo {
  padding: 1rem;
  flex-shrink: 0;
  width: 120px;

  @media (min-width: $breakpoint-desktop) {
    width: 142px;
  }
}

.Header {
  display: flex;
  color: $color-white;
  justify-content: space-between;
  @media (min-width: $breakpoint-desktop) {
    max-width: $width-max;
    margin: 0 auto;
  }
}

.navigationDesktop {
  display: none;
  flex-grow: 1;
  width: 100%;
  @media (min-width: $breakpoint-desktop) {
    display: block;
    max-width: 800px;
  }
}

.navigationMobile {
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  @media (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.right {
  font-weight: bold;
  padding: 1rem;
  text-align: right;
  flex-shrink: 0;
  @media (min-width: $breakpoint-desktop) {
    margin-top: 1rem;
  }
}
