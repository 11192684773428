.resortStatGraphic {
  display: flex;
  width: 40%;
  padding: 1rem;
  height: 50px;

  img {
    max-width: 50px;
    margin-right: 0.5rem;
    fill: red;
  }
}

.icon{
  fill: green;
}

.stats {

}
