@import "../../../layout/variables";

.BrandPage {
}

.BrandSection {
  display: flex;
}

.BrandLogoCard {
  flex-grow: 0;
  width: 100%;
  margin-right: $margin-spacing-item;

  @media(min-width: $breakpoint-desktop) {
    width: 400px;
  }
}

.BrandInfo {
  flex-grow: 1;
  padding: 0 1rem;
  margin-top: $margin-spacing-item;
}
