.lineOne,
.lineTwo {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 1rem 0;
}

.button {
  flex: 1 1 0;
  margin-right: 2px;
  border-radius: 0;
}
