@import "../../../layout/variables";

.splash {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.breadcrumbs {
  padding: 1rem;
  font-size: $font-size-medium;
  a {
    text-decoration: none;
    color: $color-turquoise;
  }
}

.headlineStats {
  display: flex;
  margin-bottom: $margin-spacing-section;
}

.headlineStatsStats {
  width: 40%;
  background-color: $color-medium-grey;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.headlineStatsImage {
  height: 320px;
  width: 60%;
  background-size: cover;
  background: $gradient;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.description {
  padding: 1rem;
}

.tabsContainer {
  border-top: $border-separator;
  border-bottom: $border-separator;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.links  {
  display: flex;
  justify-content: space-around;

  a {
    color: $color-white;
    text-decoration: none;
    padding: 1rem 2rem;
  }
}

.reviewButton {
  padding: $padding-internal-separator;
}

