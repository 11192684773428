@import "../../../layout/_variables.scss";

.sectionWhite {
  background-color: $color-white;
  :global .pageTitle {
    color: $color-dark-grey;
  }
}

.sectionGradient {
  background: $gradient;
  padding: 1rem 0;
}

.centeredButton {
  text-align: center;
  margin: $margin-space-y 0;
}

.section {
  padding: 1rem 0;
}

//horizontal scroller https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6
.horizontalCardScroller {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: $breakpoint-desktop) {
    justify-content: space-between;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &::after {
      content: "";
      flex: auto;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    justify-content: center;
  }
}
