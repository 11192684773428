@import "../../../layout/variables";

.tiles {
  display: flex;
}

.pill {
  display: inline-block;
  margin: 0 0 0.5rem 0;
  background-color: $color-turquoise;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
}
