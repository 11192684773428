@import "../../../layout/variables";

.article {
  img {
    max-width: 100%;
  }
  margin-bottom: $margin-space-y;
}
.title {
  margin-bottom: 0.5rem;
}

.date {
  padding-top: 0;
  margin-top: 2px;
  font-size: $font-size-small;
  color: $color-light-grey;
}

.allPosts {
  font-size: $font-size-breadcrumbs;
  color: $color-white;
  text-decoration: none;
  margin: 2rem 0;
  display: block;
  font-weight: bold;
}
