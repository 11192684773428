@import "../../../../layout/variables";

.section {
  background-color: $color-medium-grey;
  padding: $padding-internal;
  margin-bottom: $margin-space-y;
}

.resortStat {
  width: 300px;
  margin-right: $margin-spacing-item;
}
