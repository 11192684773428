@import "variables";
.navigation {
  display: flex;
  border-bottom: $border-separator;
  border-top: $border-separator;

  @media (min-width: $breakpoint-desktop) {
    margin-top: 2rem;
    border-bottom: 0;
    border-top: 0;
  }

  a {
    text-decoration: none;
    color: $color-white;
    padding: 1rem 0;
    display: block;
    text-transform: capitalize;
    text-align: center;

    &.active {
      color: $color-turquoise-light;
      border-bottom: 4px solid $color-turquoise-light;

      @media (min-width: $breakpoint-desktop) {
        border-bottom: 0;
        border-top: 4px solid $color-turquoise-light;
      }
    }

    &:hover {
      color: $color-turquoise;
    }
  }
}

.dropdownContainer {
  position: relative;
  flex-grow: 1;

  @media (min-width: $breakpoint-desktop) {
    &:hover {
      .dropdownContent {
        display: flex;
      }
    }
  }
}

.dropdownContent {
  display: none;
  background-color: $color-medium-grey;
  position: absolute;
  right: 0;
  top: 3.5rem;
  width: 95%;
  margin: 0 auto;
  flex-direction: column;
  z-index: 999;

  a {
    display: block;
    width: 100%;
    border-bottom: $border-separator;
    font-weight: bold;
    padding-bottom: calc(1rem + 3px);

    &:hover {
      border-bottom: 4px solid $color-turquoise;
      padding-bottom: 1rem;
    }
  }
}

