@import "../../layout/variables";

.splash {
  display: flex;
  flex-direction: column;
  background: $gradient;
  padding: 4rem 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.splashTitle {
  padding: 1rem;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  font-size: $font-size-hero;
  font-family: impact, impact-w01-2010, impact-w02-2010, impact-w10-2010,
    sans-serif;

  @media (min-width: $breakpoint-desktop) {
    font-size: $font-size-hero-desktop;
  }
}

.tagline {
  max-width: 80%;
  font-weight: normal;
  text-align: center;
  margin: 0 auto;
  color: $color-white;
  font-size: $font-size-medium;
  line-height: 180%;

  @media (min-width: $breakpoint-desktop) {
    font-size: $font-size-large;
  }
}
