@import "../../../../layout/variables";

.checkboxRow {
  display: flex;

  input[type=checkbox] {
    flex-grow: 0;
    width: 2rem;
    margin-right: $margin-spacing-item;
  }
  label {
    flex-grow: 1;
    cursor: pointer;
  }
}

.formRow {
  margin-bottom: $margin-spacing-item;

  input, textarea, select {
    width: 100%;
    border-radius: $border-radius-small;
    border: 2px solid $color-light-grey;
    background-color: $color-medium-grey;
    padding: 0.5rem;
    color: $color-white;
    font-size: $font-size-medium;
    font-family: $font-family;
    line-height: 1.5rem;

    &:focus {
      border: 2px solid $color-yellow;
      outline: 0;
    }
  }

  textarea {
    width: calc(100% - 1rem);
    height: 200px;
  }

  input {
    width: calc(100% - 1rem);
  }

  option {
    text-transform: capitalize;
  }
}

.isRequired {
  color: $color-red;
  font-weight: bold;
  font-size: $font-size-large;
  margin-left: 4px;
}

.label {
  display: block;
  font-weight: bold;
  font-size: $font-size-medium;
  padding-bottom: 0.5rem;
}
