@import "../../../../layout/variables";

.reviewPreview {
  border: $border-separator;
  display: flex;
  flex-direction: column;
  margin-bottom: $margin-spacing-item;
  padding: $padding-internal;
}

.posterInfo {
  display: flex;

  img {
    height: 50px;
    padding-right: $padding-internal-separator
  }
}

.reviewText {
  margin-top: $padding-internal-separator
}
