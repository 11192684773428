@import "../../../layout/variables";

.splash {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.splashTitle {
  margin: 100px 0 0 0;
  color: $color-yellow;
  text-align: center;
  text-transform: uppercase;
  font-size: $font-size-hero;
  font-family: impact, impact-w01-2010, impact-w02-2010, impact-w10-2010,
    sans-serif;

  @media (min-width: $breakpoint-desktop) {
    font-size: $font-size-hero-desktop;
    margin-top: 160px;
  }
}

.tagline {
  max-width: 80%;
  font-weight: normal;
  text-align: center;
  margin: 0 auto;
  color: $color-white;
  font-size: $font-size-medium;
  line-height: 180%;

  @media (min-width: $breakpoint-desktop) {
    font-size: $font-size-large;
  }
}

.topSearches {
  text-align: center;
  font-size: $font-size-large;
  margin: 4rem auto 0;
  font-weight: bold;
  display: flex;
  width: 90%;
}
.searchesBar {
  flex-grow: 1;
}
.searchesText {
  flex-shrink: 0;
}
.locations {
  display: flex;
  margin: 1rem auto;
  flex-wrap: wrap;
  overflow: hidden;
  max-width: 90%;
  justify-content: center;

  @media (min-width: $breakpoint-desktop) {
    max-width: 1008px;
  }
}

.locations .location:nth-child(n + 5) {
  display: none;
  @media (min-width: $breakpoint-desktop) {
    display: block;
  }
}

.location {
  background-color: $color-white;
  color: $color-dark-grey;
  margin: 1px;
  padding: 1rem 0;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  width: calc(90vw / 2 - 10px);

  &:hover {
    background-color: $color-very-light-grey;
    transition: $transition-button-hover;
  }

  @media (min-width: $breakpoint-desktop) {
    width: 250px;
  }
}
