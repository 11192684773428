@import "../../../layout/variables";

.rating {
  margin-top: auto;
  width: 120px;
  text-align: right;
  color: $color-turquoise;
}

.position {
  margin-top: -5px;
  padding-right: 1rem;
  font-size: $font-size-x-large;
  font-weight: bold;
  color: $color-yellow;
}

.resortDataWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.resortInfo {
  flex-grow: 1;
}

.resortLocation {
  display: block;
  font-size: $font-size-medium;
  color: $color-turquoise;
}
.resortName {
  display: block;
  margin-top: 0.25em;
  font-size: $font-size-medium;
  font-weight: bold;
}

.reviews {
  font-weight: normal;
  text-align: center;
}
