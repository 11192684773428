@import "../../../layout/variables";

.ArticleLink {
  color: $color-white;
  text-decoration: none;
  margin-bottom: 2rem;
  display: block;

  &:hover {
    color: $color-yellow;
  }
}

.articlePreview {
  border: $border-separator;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }
}

.articleContent{
  flex-grow: 1;
}

.previewText {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.articleInfo {
  color: $color-white;
  display: flex;
}

.articleInfoText {
  p {
    padding: 0;
    margin: 0;
  }
}

.profilePicture {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 1rem;
  img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin-top: 2px;
  }
}

.icon {
  fill: $color-white;
  margin-left: 4px;
  position: relative;

  svg {
    position: absolute;
    top: 0px;
  }
}

.title {
  margin-bottom: 0.5rem;
}

.date {
  padding-top: 0;
  margin-top: 2px;
  font-size: $font-size-small;
  color: $color-light-grey;
}

.image {
  flex-grow: 0;
  width: 100%;
  @media (min-width: $breakpoint-desktop) {
    max-width: 420px;
  }
}
