@import "../../layout/variables";

.link {
  width: 345px;
  flex-shrink: 0;
  padding: 1rem;
  margin-bottom: $margin-spacing-section;
  text-decoration: none;
  color: $color-white;
  filter: $filter-shadow;

  &:hover {
    transform: translateX(0) translateY(0) scaleX($hover-scale)
      scaleY($hover-scale) rotate(0deg) skewX(0deg) skewY(0deg);
    transition: all 0.4s ease-in-out 0s;
  }

  @media (min-width: $breakpoint-desktop) {
    width: 400px;
    height: 250px;
  }
}

.backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 180px;
}

.logo {
  background-color: $color-white;
  border-radius: 1rem;
  position: absolute;
  bottom: -15%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80px;
  height: 80px;
  border: 4px solid $color-white;
}

.roundedCorners {
  border-radius: $border-radius-medium $border-radius-medium 0 0;
}

.caption {
  margin: -1rem 0;
  padding: 3rem 1rem 1.5rem 1rem;
  background-color: $color-medium-grey;
  min-height: 2rem;
  display: flex;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.roundedCorners {
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
  }
}

.darkThemeCaption {
  color: $color-white;
  background-color: $color-dark-grey;
}
